<template>
  <v-app-bar
    app
    ref="navbar"
    class="white"
    flat
    clipped-left
    :height="$vuetify.breakpoint.smAndDown ? 110 : 176"
  >
    <!-- navbar links -->
    <div class="accent d-none d-md-flex align-center px-3 py-1 primary-links">
      <a
        v-for="link in infoLinks"
        class="mx-3 default--text text-decoration-none text-caption d-flex align-center font-weight-bold"
        :key="link.templateNavItemId"
        @click="clicked"
        :href="link.itemUrl"
      >
        <v-icon small class="mr-2 default--text">
          {{ link.iconSource }}
        </v-icon>
        {{ link.name }}
      </a>
    </div>

    <!-- navbar -->
    <v-row no-gutters :align="'center'" class="navbar">
      <!-- navigazione (solo smAndDown) -->
      <v-col
        cols="4"
        md="1"
        class="d-flex d-md-none justify-start align-center p-sm-auto"
      >
        <v-btn
          v-if="$routerHistory.hasPrevious() && $platform_is_cordova"
          :to="{ path: $routerHistory.previous().path }"
          elevation="0"
          aria-label="Torna Indietro"
        >
          <v-icon large elevation="0">$chevronLeft</v-icon>
        </v-btn>
        <v-app-bar-nav-icon
          x-large
          color="default"
          @click.prevent.stop="drawerLeft = !drawerLeft"
        >
          <template v-slot:label>
            <v-btn icon aria-label="Apri menu categorie">
              <v-icon>$menu</v-icon>
            </v-btn>
          </template>
        </v-app-bar-nav-icon>
      </v-col>

      <!-- logo col -->
      <v-col cols="4" md="2" lg="2" class="order-1 column">
        <!-- :to="site == 'site' ? { name: 'HomeSite' } : { name: 'Home' }" -->
        <router-link
          class="d-flex align-center justify-center"
          :to="{ name: 'HomeSite' }"
        >
          <!-- logo -->
          <img
            class="px-0 logo"
            src="/logo/logo.svg"
            alt="Logo Alisupermercati.it"
          />
        </router-link>
      </v-col>
      <!-- search col -->
      <v-col
        cols="12"
        :md="isAuthenticated ? 4 : 7"
        :lg="isAuthenticated ? 4 : 6"
        class="order-4 order-md-2 d-flex px-md-4 column mt-1 mt-md-0 px-4"
      >
        <div class="flex-grow-1 flex-shrink-0 py-1 py-md-0">
          <SearchInput class="pr-auto pr-lg-auto" />
        </div>
      </v-col>
      <!-- buttons col -->
      <v-col
        cols="4"
        :md="isAuthenticated ? 6 : 3"
        :lg="isAuthenticated ? 6 : 4"
        class="order-2 order-md-3 d-flex justify-end align-center pr-0 pr-md-3"
      >
        <v-layout v-if="!isAuthenticated" align-center justify-end>
          <v-btn @click.stop="handleLogin()" medium icon class="">
            <v-icon color="default">$profile</v-icon>
          </v-btn>
          <v-btn
            @click.stop="handleLogin()"
            medium
            text
            class="d-none d-sm-inline pl-0 font-weight-regular"
          >
            <span
              v-html="$t('navbar.signup')"
              class="text-left text-none"
            ></span>
          </v-btn>
          <v-btn icon @click.stop="handleLogin()" class="mr-2">
            <v-icon color="default">$cart</v-icon>
          </v-btn>
        </v-layout>
        <v-layout v-else align-center justify-end justify-sm-space-between>
          <CartInfoList
            class="d-none d-md-flex bg-transparent"
            v-if="
              isAuthenticated &&
                !$vuetify.breakpoint.xs &&
                !$vuetify.breakpoint.sm
            "
            :isNavbar="true"
          />
          <div class="d-flex flex-row align-center mr-2 text-caption">
            <v-menu bottom offset-y nudge-bottom="23" tile min-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on.stop="on" medium icon class="px-0">
                  <v-icon color="default">$profile</v-icon>
                </v-btn>
              </template>
              <UserMenu :user="cart.user" />
            </v-menu>
            <div class="d-none d-sm-flex flex-column">
              <div
                class="default--text"
                v-html="`Ciao <b>${cart.user.firstName}</b>`"
              />
              <div class="default px-1 rounded-sm">
                <span class="primary--text font-weight-bold mr-1">{{
                  availablePoints
                }}</span>
                <span
                  class="white--text"
                  :style="{
                    'font-size': $vuetify.breakpoint.mdAndDown ? '10px' : ''
                  }"
                  >{{ $t("navbar.points") }}</span
                >
              </div>
            </div>
            <transition name="bounce" mode="out-in">
              <v-badge
                :content="totalItems"
                :value="totalItems > 0"
                :key="totalItems"
                transition="bounce"
                color="primary"
                overlap
                :offset-x="$vuetify.breakpoint.xs ? 22 : 72"
                offset-y="22"
                class="d-flex flex-row align-center default--text font-weight-bold"
              >
                <v-btn
                  aria-label="Apri carrello"
                  medium
                  tile
                  icon
                  @click.stop="openCart()"
                  class="no-border"
                >
                  <v-icon color="default">$cart</v-icon>
                </v-btn>
                <div
                  class="default--text text-caption d-none d-sm-flex flex-column"
                  role="button"
                  @click.stop="openCart()"
                >
                  <div>Carrello</div>
                  <div
                    v-html="
                      `<b class='default--text'> ${$n(
                        cart.totalPrice,
                        'currency'
                      )} </b>`
                    "
                  ></div>
                </div>
              </v-badge>
            </transition>
          </div>
        </v-layout>
      </v-col>
    </v-row>

    <LinksMenu
      v-if="$vuetify.breakpoint.mdAndUp"
      :items="menuLinks"
      :showDrawerButton="true"
      @drawerButtonClick="drawerLeft = !drawerLeft"
    />
    <v-progress-linear
      arial-label="Caricamento in corso"
      :active="showOverlay"
      :indeterminate="showOverlay"
      absolute
      bottom
      color="primary"
    ></v-progress-linear>
  </v-app-bar>
</template>

<script>
import SearchInput from "@/components/navigation/SearchInput.vue";
import UserMenu from "@/components/navigation/UserMenu.vue";
// import Login from "@/components/Login.vue";
import LinksMenu from "@/components/navigation/LinksMenu.vue";
import CartInfoList from "@/components/cart/CartInfoList.vue";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import clickHandler from "~/mixins/clickHandler";

// import get from "lodash/get";
import { mapActions, mapState } from "vuex";

export default {
  name: "Navbar",
  mixins: [login, clickHandler, cartInfo],
  components: {
    SearchInput,
    LinksMenu,
    CartInfoList,
    UserMenu
    // Login
  },
  props: {
    infoLinks: { type: Array, required: false },
    menuLinks: { type: Array, required: false }
  },
  methods: {
    // async fetchInfoLinks() {
    //   const res = await CmService.getMenuByLocation("info-links");
    //   this.infoLinks = res;
    // },
    ...mapActions({
      doLogout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    closeLogin() {
      this.$refs.loginMenuRef.isActive = false;
    },

    goBack() {
      this.$router.go(-1);
    },

    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push({ name: "Cart" });
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        // if (await this.needAddress()) {
        //   await this.needTimeslot();
        // }
      }
    },
    historyBack() {
      window.history.back();
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      storeDrawerLinks: state => state.app.drawerLinks,
      cart: ({ cart }) => cart.cart
    }),
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    enableRegistration() {
      return global.config.registration;
    },
    totalItems() {
      return this.cart.totalItems;
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    showOverlay() {
      return this.$store.state.app.pendingRequests.count > 0;
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    },
    availablePoints() {
      return (
        this.cart &&
        this.cart.userPoints &&
        this.cart.userPoints.CAMI &&
        this.cart.userPoints.CAMI.pointsAvailable
      );
    }
  },
  created() {
    // this.fetchInfoLinks();
  }
};
</script>

<style scoped lang="scss">
.primary-links {
  height: 36px;
}

.navbar {
  background-color: $white;
  height: 95px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: 105px;
    box-shadow: 0px 2px 13pt #0000004d;
  }
}

.v-menu__content {
  border-radius: 0 0 12px 12px !important;
}

.logo {
  max-height: 70px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    max-height: 50px;
  }
}

::v-deep .v-toolbar__content {
  display: block;
  padding: 0px;
  transition: height 0.2s ease;
  -webkit-transition: height 0.2s ease;
  background-color: var(--v-grey-lighten3);
}
</style>
