<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <div class="navbar-menu d-flex flex-row align-center primary">
    <v-btn
      v-if="showDrawerButton"
      depressed
      tile
      color="primary lighten-1"
      class="drawer-button default--text d-flex flex-row"
      @click.prevent.stop="$emit('drawerButtonClick')"
    >
      <v-img eager contain src="/favicon.png" height="24" max-width="50" />
      <span v-if="$vuetify.breakpoint.mdAndUp">
        {{ $t("navbar.products") }}
      </span>
      <v-icon color="default" class="ml-4">$chevronDown</v-icon>
    </v-btn>

    <div class="items-wrapper">
      <div
        v-for="(item, idx) in items"
        v-bind:key="idx"
        class="mx-2 mx-md-3 d-flex justify-center align-center"
      >
        <a
          v-if="item.templateNavItems?.length === 0"
          class="px-1 pl-md-3 text-decoration-none"
          :class="item.classes"
          :href="item.itemUrl"
          :target="item.target || '_self'"
          @click.prevent="clicked"
        >
          {{ item.name }}
        </a>
        <v-menu
          v-else
          bottom
          offset-y
          tile
          nudge-bottom="12"
          content-class="children-links-menu px-12 py-6"
        >
          <template v-slot:activator="{ on, attrs }">
            <a
              :id="`header-menu-${item.templateNavItemId}`"
              v-bind="attrs"
              v-on.stop="on"
              class="px-1 px-lg-3"
              :class="item.classes"
            >
              <span class="mr-1">{{ item.name }}</span>
              <v-icon
                color="default"
                class=""
                v-if="item.templateNavItems?.length > 0"
              >
                $chevronDown
              </v-icon>
            </a>
          </template>
          <v-row no-gutters>
            <v-col cols="8">
              <v-row no-gutters>
                <v-col
                  cols="3"
                  v-for="(child,
                  idx2) in filteredExcludedNavItemNavigationType(
                    item.templateNavItems,
                    [3]
                  )"
                  :key="idx2"
                  class="px-1"
                >
                  <a
                    class="text-body-2 text-uppercase font-weight-bold default--text"
                    :class="child.classes"
                    :href="child.itemUrl || '#'"
                    :target="child.target || '_self'"
                    @click.prevent="clicked"
                  >
                    {{ child.name }}
                  </a>
                  <div class="d-flex flex-column">
                    <a
                      v-for="(child2, idx3) in child.templateNavItems"
                      :key="idx3"
                      class="text-body-2 default--text my-1"
                      :class="child2.classes"
                      :target="child2.target || '_self'"
                      :href="child2.itemUrl || '#'"
                      @click.prevent="clicked"
                    >
                      <span :class="child2.classes">{{ child2.name }}</span>
                    </a>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" class="d-flex flex-column align-end">
              <MenuBannerCard
                v-for="navItemBanner in filteredIncludedNavItemNavigationType(
                  item.templateNavItems,
                  [3]
                )"
                :key="navItemBanner.templateNavItemId"
                :bannerCard="navItemBanner"
              />

              <!-- <MenuBlocks :UxBlocksCategoryId="linkMap[item.ID]"></MenuBlocks> -->
            </v-col>
          </v-row>
        </v-menu>
      </div>
    </div>
  </div>
  <!-- eslint-enable vue/no-use-v-if-with-v-for -->
</template>

<script>
// import MenuBlocks from "@/components/wordpress/MenuBlocks.vue";
import MenuBannerCard from "@/components/navigation/MenuBannerCard.vue";

import clickHandler from "~/mixins/clickHandler";

export default {
  name: "LinksMenu",
  components: {
    // MenuBlocks
    MenuBannerCard
  },
  mixins: [clickHandler],
  props: {
    links: { type: Array, required: false },
    items: { type: Array, required: true },
    showDrawerButton: { type: Boolean, default: true }
  },
  data() {
    return {};
  },
  methods: {
    filteredIncludedNavItemNavigationType(navItems, type = [3]) {
      return navItems.filter(
        navItem =>
          navItem.templateNavItemNavigationType &&
          navItem.templateNavItemNavigationType
            .templateNavItemNavigationTypeId &&
          type.includes(
            navItem.templateNavItemNavigationType
              .templateNavItemNavigationTypeId
          )
      );
    },
    filteredExcludedNavItemNavigationType(navItems, type = [3]) {
      return navItems.filter(
        navItem =>
          !navItem.templateNavItemNavigationType ||
          !navItem.templateNavItemNavigationType
            .templateNavItemNavigationTypeId ||
          !type.includes(
            navItem.templateNavItemNavigationType
              .templateNavItemNavigationTypeId
          )
      );
    }
  }
};
</script>

<style scoped lang="scss">
.navbar-menu {
  height: $navbar-menu-height;
  .drawer-button {
    height: $navbar-menu-height;
    min-width: $drawer-btn-width;
  }
  .items-wrapper {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    height: 45px;
  }

  a {
    color: var(--v-default-base) !important;
    line-height: 2;
  }
}

.children-links-menu {
  background-color: white;
  width: 100%;
  left: 0 !important;
  top: 175px !important;
  max-width: 100%;
  border-radius: 0;
  border-bottom: 2px solid $primary;
  z-index: 11 !important;
  .v-menu__content {
    box-shadow: 0px 3px 6px #00000029;
  }
  a {
    text-decoration: none;
  }
  .v-card {
    &.yellow-gradient {
      background-color: #dda801;
      background: linear-gradient(
        0deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(191, 145, 6, 1) 28%,
        rgba(205, 156, 4, 1) 39%,
        #dda801 44%
      );
    }
    &.transparent-gradient {
      .v-card__title,
      .v-card__subtitle {
        z-index: 2;
      }
      .gradient-layer {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, black, transparent);
      }
    }
  }
}
</style>
