<template>
  <div class="cart-info-list d-flex flex-row">
    <img height="48" v-if="!isCart" aria-hidden :src="serviceImgNoLabel" />
    <div class="d-flex flex-column">
      <v-btn
        class="cart-info-button text-none font-weight-regular"
        :class="
          (isCart ? 'text-body-2' : 'text-caption',
          isNavbar ? 'font-weight-bold' : 'font-weight-regular')
        "
        :disabled="!editableAddress"
        text
        tile
        small
        elevation="0"
        rounded
        @click="openAddressSelector"
      >
        <span
          class="info-text text-uppercase"
          :class="
            isCart
              ? 'text-body-2 line-height-1'
              : 'text-caption font-weight-bold'
          "
          >{{ shippingAddress }}
        </span>
        <v-icon right v-if="!isCart">$edit</v-icon>
      </v-btn>
      <v-btn
        class="cart-info-button text-none font-weight-regular"
        :class="isCart ? 'text-body-2' : 'text-caption'"
        :disabled="!editableTimeslot"
        text
        tile
        small
        elevation="0"
        rounded
        @click="openTimeslotSelector"
      >
        <span class="info-text">{{ timeslot }}</span>
        <v-icon right v-if="!isCart">$edit</v-icon></v-btn
      >
    </div>
  </div>
</template>
<style lang="scss" scoped>
.cart-info-list {
  .mini-service {
    width: 24px;
    height: 24px;
  }
  .info-text {
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 1024px) {
      max-width: 160px;
    }

    @media (min-width: 1025px) and (max-width: 1200px) {
      max-width: 230px;
    }
    @media (min-width: 1201px) {
      max-width: 270px;
    }
    @media (min-width: 1501px) {
      max-width: 500px;
    }
    // @media #{map-get($display-breakpoints, 'lg-and-up')} {
    //   max-width: 300px;
    // }
    // @media #{map-get($display-breakpoints, 'xl-only')} {
    //   max-width: 80%;
    // }
  }
  .line-height-1 {
    line-height: 1 !important;
  }
  .avatar {
    img {
      height: 36px;
    }
    @media (max-width: 1049px) {
      display: none !important; // override for small screens
    }
  }
  .cart-info-button {
    justify-content: left;
    font-size: 12px;
    height: 20px !important;
    letter-spacing: normal;
    .v-btn__content {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .v-icon {
      font-size: $font-size-root;
      height: 14px;
      width: 14px;
    }
  }
  .cart-edit-icon {
    position: absolute;
    right: 20px;
  }
  .cart-info-label {
    max-width: 212px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .v-list-item--dense .v-list-item__content,
  .v-list--dense .v-list-item .v-list-item__content {
    padding: 1px 0;
  }
}
</style>
<script>
import get from "lodash/get";
import { mapState } from "vuex";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
export default {
  name: "CartInfoList",
  mixins: [login, cartInfo],
  data() {
    return {
      maxWidth: 320,
      maxHeight: 60
    };
  },
  props: {
    isCart: { type: Boolean, required: false },
    isNavbar: { type: Boolean, required: false }
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    shippingAddress() {
      let shippingAddress = get(this.cart, "shippingAddress");
      let result = null;
      if (shippingAddress) {
        if (shippingAddress.deliveryServiceId === 1) {
          result = shippingAddress.addressName;
        } else if (shippingAddress.deliveryServiceId === 2) {
          result = this.$t(
            "navbar.address." + shippingAddress.addressTypeId + ".format",
            shippingAddress
          );
        }
        return result;
      } else {
        return this.$t("navbar.noShippingAddress");
      }
    },
    serviceImgNoLabel() {
      return this.$t(
        "navbar.service." + this.getDeliveryServiceId + ".iconNoLabel",
        {
          color: this.color
        }
      );
    }
  }
};
</script>
