var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-menu d-flex flex-row align-center primary"},[(_vm.showDrawerButton)?_c('v-btn',{staticClass:"drawer-button default--text d-flex flex-row",attrs:{"depressed":"","tile":"","color":"primary lighten-1"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('drawerButtonClick')}}},[_c('v-img',{attrs:{"eager":"","contain":"","src":"/favicon.png","height":"24","max-width":"50"}}),(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("navbar.products"))+" ")]):_vm._e(),_c('v-icon',{staticClass:"ml-4",attrs:{"color":"default"}},[_vm._v("$chevronDown")])],1):_vm._e(),_c('div',{staticClass:"items-wrapper"},_vm._l((_vm.items),function(item,idx){return _c('div',{key:idx,staticClass:"mx-2 mx-md-3 d-flex justify-center align-center"},[(item.templateNavItems?.length === 0)?_c('a',{staticClass:"px-1 pl-md-3 text-decoration-none",class:item.classes,attrs:{"href":item.itemUrl,"target":item.target || '_self'},on:{"click":function($event){$event.preventDefault();return _vm.clicked.apply(null, arguments)}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('v-menu',{attrs:{"bottom":"","offset-y":"","tile":"","nudge-bottom":"12","content-class":"children-links-menu px-12 py-6"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({staticClass:"px-1 px-lg-3",class:item.classes,attrs:{"id":`header-menu-${item.templateNavItemId}`}},'a',attrs,false),on),[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.name))]),(item.templateNavItems?.length > 0)?_c('v-icon',{attrs:{"color":"default"}},[_vm._v(" $chevronDown ")]):_vm._e()],1)]}}],null,true)},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.filteredExcludedNavItemNavigationType(
                  item.templateNavItems,
                  [3]
                )),function(child,idx2){return _c('v-col',{key:idx2,staticClass:"px-1",attrs:{"cols":"3"}},[_c('a',{staticClass:"text-body-2 text-uppercase font-weight-bold default--text",class:child.classes,attrs:{"href":child.itemUrl || '#',"target":child.target || '_self'},on:{"click":function($event){$event.preventDefault();return _vm.clicked.apply(null, arguments)}}},[_vm._v(" "+_vm._s(child.name)+" ")]),_c('div',{staticClass:"d-flex flex-column"},_vm._l((child.templateNavItems),function(child2,idx3){return _c('a',{key:idx3,staticClass:"text-body-2 default--text my-1",class:child2.classes,attrs:{"target":child2.target || '_self',"href":child2.itemUrl || '#'},on:{"click":function($event){$event.preventDefault();return _vm.clicked.apply(null, arguments)}}},[_c('span',{class:child2.classes},[_vm._v(_vm._s(child2.name))])])}),0)])}),1)],1),_c('v-col',{staticClass:"d-flex flex-column align-end",attrs:{"cols":"4"}},_vm._l((_vm.filteredIncludedNavItemNavigationType(
                item.templateNavItems,
                [3]
              )),function(navItemBanner){return _c('MenuBannerCard',{key:navItemBanner.templateNavItemId,attrs:{"bannerCard":navItemBanner}})}),1)],1)],1)],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }