<template>
  <v-list class="user-menu">
    <v-list-item :to="{ name: 'Dashboard' }">
      <v-list-item-title>{{ $t("profile.menu.dashboard") }}</v-list-item-title>
    </v-list-item>
    <v-list-item :to="{ name: 'Orders' }">
      <v-list-item-title>{{ $t("profile.menu.orders") }}</v-list-item-title>
    </v-list-item>
    <v-list-item :to="{ name: 'ProfileUpdate' }">
      <v-list-item-title>{{ $t("profile.menu.profile") }}</v-list-item-title>
    </v-list-item>
    <v-list-item :to="{ name: 'Lists' }">
      <v-list-item-title>{{ $t("profile.menu.lists") }}</v-list-item-title>
    </v-list-item>
    <v-list-item @click.stop="onLogoutClick">
      <v-list-item-title>{{ $t("profile.menu.logout") }}</v-list-item-title>
    </v-list-item>
    <!-- <v-row no-gutters>
      <v-col cols="6">

        <v-list-item :to="{ name: 'Addresses' }">
          <v-list-item-title>{{
            $t("profile.menu.addresses")
          }}</v-list-item-title>
        </v-list-item>
      </v-col>

      <v-col cols="6">
        <v-list-item :to="{ name: 'Awards' }">
          <v-list-item-title>Premi</v-list-item-title>
        </v-list-item>
        <v-list-item
          :to="{
            name: 'Booking',
            params: { listId: listData?.cartInfos?.WISHLIST_ID }
          }"
        >
          <v-list-item-title>{{
            $t("profile.menu.rewardsCart")
          }}</v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'BookedAwards' }">
          <v-list-item-title>{{
            $t("profile.menu.requestedRewards")
          }}</v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'ProfileGift' }">
          <v-list-item-title>{{ $t("profile.menu.gifts") }}</v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'CreateOffer' }">
          <v-list-item-title>{{
            $t("profile.menu.createOffer")
          }}</v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'Donate' }">
          <v-list-item-title>{{
            $t("profile.menu.donate.link")
          }}</v-list-item-title>
        </v-list-item>
      </v-col>
    </v-row> -->
  </v-list>
</template>
<style scoped lang="scss">
.user-menu {
  .v-list-item--active {
    &::before {
      opacity: 0 !important;
    }
    .v-list-item__title {
      font-weight: bold;
    }
  }
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";
import customService from "@/service/customService";

export default {
  props: {
    user: { type: Object, required: true }
  },
  data() {
    return {
      coudlBecomeTco: false,
      listData: null
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      getLegalById: "cart/getLegalById"
    })
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout"
    }),
    async onLogoutClick() {
      let isLoggedOut = await this.doLogout();
      if (isLoggedOut) {
        if (this.$route.name !== "Home") {
          this.$router.push({ name: "Home" });
        }
      }
    },
    async fetchList() {
      let res = await customService.getList(6, false);
      this.listData = res ? res : null;
    }
  },
  mounted() {
    this.fetchList();
  }
};
</script>
